<template>
  <div>
    <div class="flex ...">
      <div class="flex-none w-3/12 h-14 ..."></div>
      <div class="grow">
        <UCard class="text-center">{{ valuation.toFixed(2) }} {{ currency }} </UCard>
      </div>
      <div class="flex-none w-3/12 h-14 ..."></div>
    </div>
    <UTable :rows="accounts">
      <template #value-header="{ column }">
        <div style="text-align: right">{{ column.label }}</div>
      </template>
      <template #quantity-header="{ column }">
        <div style="text-align: right">{{ column.label }}</div>
      </template>

      <template #value-data="{ row }">
        <div style="text-align: right">{{ row.value.toFixed(2) }} <UBadge variant="soft" size="xs">EUR</UBadge></div>
      </template>

      <template #quantity-data="{ row }">
        <div style="text-align: right">
          {{ row.quantity.toFixed(2) }} <UBadge variant="soft" size="xs">{{ row.currency }}</UBadge>
        </div>
      </template></UTable
    >
  </div>
</template>

<script setup>
const app = useAppConfig();

const valuation = ref(0.0);
const currency = ref("?");

const refreshValuation = async () => {
  const data = await $fetch(app.service + "/portfolios/default");
  valuation.value = data?.quantity;
  currency.value = data?.currency;
};

onMounted(() => {
  refreshValuation();
  refreshAccounts();
});

const accounts = ref([]);

const refreshAccounts = () => {
  $fetch(app.service + "/positions").then((data) => {
    accounts.value = data;
  });
};
</script>
